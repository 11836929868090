
<template>
	<div class="pt-20">

		<a-row :gutter="24" class="">

			<a-col :span="24" :md="6" :lg="6" :xl="6" class="mb-24">
				<!-- <a-card :bordered="false" class="widget-1" v-if="deviceWidth >= miniWidth">
					<a-statistic
						title="Active Code"
						:value="activeCode.toString()"
						prefix=""
						suffix=""
						:precision="0"
						class="text-success"
						:class="'text-warning'"
					>
					</a-statistic>
					<div class="icon" style="background-color: #734EBF;" @click="copyCode(activeCode)">
						<a-icon type="copy" theme="outlined" class="text-silabu mb-0 pb-0" style="font-size: 22px;"/>
					</div>
				</a-card> -->

				<a-card class="px-0 mx-0 pt-0 pb-0 my-0" style="background: #F4F3FC;">
					<a-row class="px-0 mx-0 pb-0 mb-0" v-if="promoCodes != null && promoCodes.length > 0 && activeCode.length > 0 && !stat.loading">
						<a-col :span="18" class="py-0 my-0" v-if="activeCode.length > 0">
							<a-row class="py-0 my-0">
								<a-col :span="24" class="py-0 my-0">
									<p class="pt-0 mt-0 pb-0 mb-0" style="color: #8C8C8C; font-weight: 600;">Promo Code</p>
								</a-col>
								<a-col :span="24" class="py-0 my-0">
									<h4 class="py-0 my-0 ">{{ activeCode }}</h4>
								</a-col>
							</a-row>
						</a-col>

						<a-col :span="18" class="py-0 my-0" v-if="activeCode.length == null || activeCode.length == 0">
							<a-row class="py-0 my-0">
								<a-col :span="24" class="py-0 my-0">
									<p class="pt-0 mt-0 pb-0 mb-0" style="color: #8C8C8C; font-weight: 600;">Promo Code</p>
								</a-col>
								<a-col :span="24" class="py-0 my-0">
									<h4 class="py-0 my-0 ">{{ 'No Code' }}</h4>
								</a-col>
							</a-row>
						</a-col>

						<a-col :span="6" class="text-right mb-0 pb-0 pt-10" style="padding-top: 0px; background: #F4F3FC;">
							<a-button id="copyBtn" type="text" @click="copyCode(activeCode)" style="background: #F4F3FC;">
								<a-icon type="copy" theme="outlined" class="text-silabu mb-0 pb-0" style="font-size: 22px;"/>
								<p id="copyTxt" class="mt-0 pt-0">Copy</p> 
							</a-button>
						</a-col>
					</a-row>

					<a-row v-if="promoCodes.length == 0 && !stat.loading">
						<a-col :span="24" class="py-10">
							<a-row class="px-0 mx-0 text-center mb-0 pb-0">
								<a-col :span="24" class="">
									<h6 class="text-silabu pt-0 mt-0">No Code has been generated</h6>
								</a-col>
							</a-row>
						</a-col>
					</a-row>

					<a-row v-if="stat.loading">
						<a-col :span="24" class="pt-9 pb-10">
							<a-row class="px-0 mx-0 text-center mb-0">
								<a-col :span="24" class="">
									<p class="text-silabu pt-0 mt-15">loading ...</p>
								</a-col>
							</a-row>
						</a-col>
					</a-row>
				</a-card>
			</a-col>

			<a-col :span="24" :md="6" :lg="6" :xl="6" class="mb-24">
				<a-card :bordered="false" class="widget-1">
					<a-statistic
						title="Total Earnings"
						:value="stat.totalEarnings"
						prefix=""
						suffix=""
						:precision="0"
						class="text-success"
						:class="'text-warning'"
					>
					</a-statistic>
					<div class="icon" style="background-color: #734EBF;">
						<!-- <h6 class="text-white pt-10" style="font-size: 18px;">TZS</h6> -->
						<img src="../../public/images/coins.png" alt="" width="20px" height="20px" style="border-radius: 0px;"> 
						<!-- <a-icon type="copy" theme="outlined" class="text-silabu mb-0 pb-0" style="font-size: 22px;"/> -->
					</div>
				</a-card>
			</a-col>
			
			<a-col :span="24" :md="6" :lg="6" :xl="6" class="mb-24">
				<WidgetCounter
					title="Bookings"
					:value="stat.bookingCount"
					prefix=""
					suffix=""
					:icon="stat.iconUser"
					status=""
				></WidgetCounter>
			</a-col>

			<a-col :span="24" :md="6" :lg="6" :xl="6" class="mb-24">
				<WidgetCounter
					title="No. of Users"
					:value="stat.userCount"
					prefix=""
					suffix=""
					:icon="stat.iconUser"
					status=""
				></WidgetCounter>
			</a-col>
		</a-row>

		<a-row :gutter="24">
			<a-col :span="24" class="">
				<a-tabs v-model="activeTab">
					<a-tab-pane :key="1" tab="YOUR PROMO CODES">
						<a-card class="mt-20 px-20" v-if="deviceWidth >= miniWidth">
							<a-row :gutter="24" :md="12" class="px-20">
								<a-col :span="12" class="mt-10 mb-10">
									<h5>List of Your Promo Codes</h5>
								</a-col>
								<a-col :span="12" class="text-right mb-24">
									<a-button id="generateBtn" :loading="isGenerateLoading" @click="onGeneratePromoCode" type="silabu" class="mt-10 text-white" >
										GENERATE PROMO CODE
									</a-button>
								</a-col>

							</a-row>
							<a-row :gutter="24">
								<a-col :span="24">
									<a-table class="mt-20 px-20"  
										size="middle"
										:bordered="false"
										:columns="promoCodeColumns" 
										:data-source="promoCodes" 
										:rowKey="record => record._id"
										:loading="stat.loading" 
										:pagination="{pageSize: 10}">

											<template slot="s_no" slot-scope="s_no, record, index">
												{{ index + 1 }}
											</template>

											<template slot="code" slot-scope="code">
												<strong>{{ code }}</strong>
											</template>

											<template slot="campaign" slot-scope="campaign">
												{{ campaign.name }}
											</template>

											<template slot="commission" slot-scope="commission, record">
												{{ record.campaign.commission }}%
											</template>

											<template slot="discount" slot-scope="discount, record">
												{{ record.campaign.discount }}%
											</template>

											<template slot="expireDate" slot-scope="expireDate, record">
												{{ moment(record.campaign.endDate).format("DD MMM YYYY") }}
											</template>

											<template slot="status" slot-scope="status">
												<a-badge count="Inactive" v-if="status != 1"/>
												
												<a-badge count="Active" v-if="status == 1" :number-style="{ backgroundColor: '#52c41a' }" />
											</template>

											<template slot="createdAt" slot-scope="createdAt">
												{{ moment(createdAt).format("DD MMM YYYY") }}
											</template>

											<template slot="action" slot-scope="action, record">
												<a-button id="txtBtn" type="text" class="pb-0 pt-0" style="color: #734EBF;" @click="copyCode(record.code)">
													<a-icon type="copy" theme="outlined"/> Copy Code
												</a-button>
												<!-- <a-button id="txtBtn" type="text" class="pb-0 pt-0" style="color: #F44335;" @click="showDeletionConfirmation(record._id)">
													<a-icon type="delete" theme="outlined"/> Delete
												</a-button> -->
											</template>
										
									</a-table>
								</a-col>
							</a-row>

						</a-card>

						<a-card class="mt-20" v-if="deviceWidth < miniWidth">
							<a-row :gutter="24" class="mb-24 text-center ">
								<a-col :span="24" :sm="24" class="mt-10 mb-0 text-center">
									<h5>List of Promo Codes</h5>
								</a-col>
								<a-col :span="24" :sm="24" class="text-center mb-24">
									<a-button id="generateBtn" :loading="isGenerateLoading" @click="onGeneratePromoCode" type="silabu" class="mt-10 text-white text-center" >
										GENERATE PROMO CODE
									</a-button>
								</a-col>

							</a-row>

							<a-row v-if="!stat.loading">
								<a-col :span="24" v-for="(option, index) in promoCodes" :key="option._id" class="mb-24">
									<a-card class="px-0 mx-0" style="margin-bottom: 20px;">
										<a-row class="px-0 mx-0">
											<a-col :span="4" class="" style="padding-top: 5px !important;">
												<label style="">{{ index + 1 }}.</label>
											</a-col>
											<a-col :span="20" class="">
												<a-row class="px-0 mx-0 mb-0 pb-0">
													<a-col :span="20" class="">
														<h5 class="pt-0 mt-0">{{ option.code }}</h5>
													</a-col>

													<a-col :span="4" class="text-right mb-0 pb-0" style="padding-top: 0px">

														<a-button id="copyBtn" type="text" @click="copyCode(option.code)">
															<a-icon type="copy" theme="outlined" class="text-silabu mb-0 pb-0" style="font-size: 22px;"/>
														</a-button>
														<!-- <small class="mt-0 pt-0">Copy</small>  -->
													</a-col>
												</a-row>
												
												<p class="mt-0 pt-0 mb-0 pb-0" style="">Campaign: <strong>{{ option.campaign.name }}</strong></p>
												<p class="mt-0 pt-0 mb-0 pb-0" style="">Commission: <strong>{{ option.campaign.commission }}%</strong></p>
												<p class="mt-0 pt-0 mb-0 pb-0" style="">Discount: <strong>{{ option.campaign.discount }}%</strong></p>
												<p class="mt-0 pt-0 mb-0 pb-0" style="">Expire Date: <strong>{{ moment(option.campaign.endDate).format("DD MMM YYYY") }}</strong></p>
											</a-col>
											
										</a-row>
									</a-card>
								</a-col> 
							</a-row>

							<a-row class="px-0 mx-0 text-center mb-0 pb-0" v-if="stat.loading">
								<a-col :span="24" class="">
									<p class="text-silabu pt-0 mt-0">loading ...</p>
								</a-col>
							</a-row>

						</a-card>
					</a-tab-pane>
					<a-tab-pane :key="2" tab="PROMO CODE BOOKINGS" force-render>
						<a-card class="px-20 mt-20" v-if="deviceWidth >= miniWidth">
							<a-row :gutter="24" class="px-20">
								<a-col :span="24" class="mt-10">
									<h5>List of Promo Code Bookings</h5>
								</a-col>
							</a-row>
							<a-row :gutter="24">
								<a-col :span="24">
									<a-table class="mt-20 px-20"  
										size="middle"
										:bordered="false"
										:columns="usersColumns" 
										:data-source="promoCodeUsers" 
										:rowKey="record => record.uuid"
										:loading="pagination.loading" 
										:pagination="{pageSize: 10}">

											<template slot="s_no" slot-scope="s_no, record, index">
												{{ index + 1 }}
											</template>

											<template slot="code" slot-scope="code">
												{{ code.code }}
											</template>

											<template slot="commissionAmount" slot-scope="commissionAmount">
												<strong>{{ commissionAmount.toLocaleString() }}/=</strong>
											</template>

											<template slot="discountAmount" slot-scope="discountAmount">
												{{ discountAmount.toLocaleString() }}/=
											</template>

											<template slot="createdAt" slot-scope="createdAt">
												{{ moment(createdAt).format("DD MMM YYYY") }}
											</template>
										
									</a-table>
								</a-col>
							</a-row>
						</a-card>

						<a-card class="mt-20" v-if="deviceWidth < miniWidth">
							<a-row :gutter="24" class="mb-24 text-center ">
								<a-col :span="24" :sm="24" class="mt-10 mb-0 text-center">
									<h5>List of Promo Users</h5>
								</a-col>
							</a-row>

							<a-row v-if="!stat.loading">
								<a-col :span="24" v-for="(option, index) in promoCodeUsers" :key="option._id" class="mb-24">
									<a-card class="px-0 mx-0" style="margin-bottom: 20px;">
										<a-row class="px-0 mx-0">
											<a-col :span="4" class="" style="padding-top: 5px !important;">
												<label style="">{{ index + 1 }}.</label>
											</a-col>
											<a-col :span="20" class="">
												
												<h6 class="pt-5 mt-0">{{ option.fullName }}</h6>
													

												<p class="mt-0 pt-0 mb-0 pb-0" style="">Commission: <strong>{{ option.commissionAmount.toLocaleString() }}/=</strong></p>
												<p class="mt-0 pt-0 mb-0 pb-0" style="">Discount: <strong>{{ option.discountAmount.toLocaleString() }}/=</strong></p>
												<p class="mt-0 pt-0 mb-0 pb-0" style="">Code: <strong>{{ option.code.code }}</strong></p>
												<p class="mt-0 pt-0 mb-0 pb-0" style="">Date: <strong>{{ moment(option.createdAt).format("DD MMM YYYY") }}</strong></p>
											</a-col>
											
										</a-row>
									</a-card>
								</a-col> 
							</a-row>

							<a-row class="px-0 mx-0 text-center mb-0 pb-0" v-if="stat.loading">
								<a-col :span="24" class="">
									<p class="text-silabu pt-0 mt-0">loading ...</p>
								</a-col>
							</a-row>

						</a-card>
					</a-tab-pane>
				</a-tabs>
			</a-col>
		</a-row>
		
		<!-- <a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" class="" style="min-height: 80vh">
			<a-col :span="24" :md="12" :lg="12" :xl="{span: 6, offset: 0}" class="text-center justify-items-center">

				<span class="" style="font-size: 30px;">Coming soon !!!</span>

			</a-col>
		</a-row> -->


		<!-- FORM MODAL -->
		<a-modal v-model="newItem.showModal" :footer="null">
			<h4 class="text-center">Generate New Promo Code</h4>
			<a-form-model
				:model="newItem"
                class="login-form mb-0 pb-0"
                layout="vertical"
				@submit.prevent="generatePromoCode">

				<a-form-item 
					class="mt-20 mb-10 pb-0" 
					label="Campaign"
					placeholder="Select Campaign"
					:colon="false">
					<a-select v-model="newItem.campaignId" placeholder="Select Campaign">
						<a-select-option v-for="option in campaigns"
							:value="option._id"
							:key="option.name">
							{{ option.name }}
						</a-select-option>
					</a-select>
				</a-form-item>

				<a-form-item class="mb-0 pb-2" style="margin-top: 20px;"> 
					<a-button id="generateBtn" type="silabu" :loading="newItem.loading" html-type="submit" class="">
						Submit
					</a-button>
				</a-form-item>

			</a-form-model>
			
		</a-modal>


		<!-- DELETE CONFIRMATION -->
		<a-modal v-model="deleteDataItem.showModal" :footer="null" width="416px">

			<a-row :gutters="24" class="">
				<a-col :span="24" :md="3" :lg="3" :xl="3" class="mt-10">
					<a-icon type="warning" theme="outlined" class="text-warning" style="font-size: 25px;"/>
				</a-col>

				<a-col :span="24" :md="21" :lg="21" :xl="21">

					<a-row :gutters="24">
						<a-col :span="24">
							<h6>Do you want to delete this data?</h6>
							<p>Once you delete, you will not be able to recover this inforamtion.</p>
						</a-col>
					</a-row>

					<a-row :gutters="24">
						<a-col :span="24" class="text-right">
							<a-button id="txtBtn" type="text" class="text-secondary" @click="cancelItemDeletion">
								Cancel
							</a-button>
							&nbsp;&nbsp;
							<a-button id="txtBtn" type="text" class="text-danger" @click="onDeleteItem">
								 Delete
							</a-button>
						</a-col>
					</a-row>
				</a-col>
			</a-row>
      
		</a-modal>

	</div>
</template>

<script>
	// Counter Widgets
	import { notification } from 'ant-design-vue';
	import WidgetCounter from '../components/Widgets/WidgetCounter' ;


	const promoCodeColumns = [
		{
            title: 'S/N',
            dataIndex: 's_no',
			scopedSlots: { customRender: 's_no' },
        },
		{
			title: 'CODE',
			dataIndex: 'code',
			scopedSlots: { customRender: 'code' },
			// width: "30%"
		},
		{
			title: 'DISCOUNT',
			dataIndex: 'discount',
			scopedSlots: { customRender: 'discount' },
			// width: "30%"
		},
		{
			title: 'COMMISSION',
			dataIndex: 'commission',
			scopedSlots: { customRender: 'commission' },
			// width: "30%"
		},
		{
			title: 'CAMPAIGN',
			dataIndex: 'campaign',
			scopedSlots: { customRender: 'campaign' },
			// width: "30%"
		},
		{
			title: 'CAMPAIGN EXPIRE DATE',
			dataIndex: 'expireDate',
			scopedSlots: { customRender: 'expireDate' },
			// width: "30%"
		},
		// {
		// 	title: 'NO. OF TIMES USED',
		// 	dataIndex: 'count',
		// 	scopedSlots: { customRender: 'count' },
		// 	// width: "30%"
		// },
		{
			title: 'STATUS',
			dataIndex: 'status',
			scopedSlots: { customRender: 'status' },
			// width: "30%"
		},
		{
			title: 'DATE',
			dataIndex: 'createdAt',
			scopedSlots: { customRender: 'createdAt' },
		},
		{
			title: 'ACTION',
			dataIndex: 'action',
			scopedSlots: { customRender: 'action' },
			// align: "center"
		},
	];

	const usersColumns = [
		{
            title: 'S/N',
            dataIndex: 's_no',
			scopedSlots: { customRender: 's_no' },
        },
		{
			title: 'FULL NAME',
			dataIndex: 'fullName',
			scopedSlots: { customRender: 'fullName' },
			// width: "30%"
		},
		{
			title: 'CODE',
			dataIndex: 'code',
			scopedSlots: { customRender: 'code' },
			// width: "30%"
		},
		{
			title: 'COMMISSION AMOUNT',
			dataIndex: 'commissionAmount',
			scopedSlots: { customRender: 'commissionAmount' },
			// width: "30%"
		},
		{
			title: 'DISCOUNT AMOUNT',
			dataIndex: 'discountAmount',
			scopedSlots: { customRender: 'discountAmount' },
			// width: "30%"
		},
		{
			title: 'DATE',
			dataIndex: 'createdAt',
			scopedSlots: { customRender: 'createdAt' },
		},
		// {
		// 	title: 'ACTION',
		// 	dataIndex: 'action',
		// 	scopedSlots: { customRender: 'action' },
		// 	// align: "center"
		// },
	];

	const data= [
		{
			title: 'Ant Design Title 1',
		},
		{
			title: 'Ant Design Title 2',
		},
		{
			title: 'Ant Design Title 3',
		},
		{
			title: 'Ant Design Title 4',
		},
	];
	export default ({
		
		components: {
			WidgetCounter
		},
		data() {
			return {
				data,
				stat: {
					totalEarnings: 0,
					userCount: 0,
					bookingCount: 0,
					installs: 0,
					clicks: 0,
					daysActive: 0,
					loading: false,
					iconUser: `
						<svg width="22" height="22" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M9 6C9 7.65685 7.65685 9 6 9C4.34315 9 3 7.65685 3 6C3 4.34315 4.34315 3 6 3C7.65685 3 9 4.34315 9 6Z" fill="#111827"/>
							<path d="M17 6C17 7.65685 15.6569 9 14 9C12.3431 9 11 7.65685 11 6C11 4.34315 12.3431 3 14 3C15.6569 3 17 4.34315 17 6Z" fill="#111827"/>
							<path d="M12.9291 17C12.9758 16.6734 13 16.3395 13 16C13 14.3648 12.4393 12.8606 11.4998 11.6691C12.2352 11.2435 13.0892 11 14 11C16.7614 11 19 13.2386 19 16V17H12.9291Z" fill="#111827"/>
							<path d="M6 11C8.76142 11 11 13.2386 11 16V17H1V16C1 13.2386 3.23858 11 6 11Z" fill="#111827"/>
						</svg>`,
					iconMoney: `
						<svg width="22" height="22" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M8.43338 7.41784C8.58818 7.31464 8.77939 7.2224 9 7.15101L9.00001 8.84899C8.77939 8.7776 8.58818 8.68536 8.43338 8.58216C8.06927 8.33942 8 8.1139 8 8C8 7.8861 8.06927 7.66058 8.43338 7.41784Z" fill="#111827"/>
							<path d="M11 12.849L11 11.151C11.2206 11.2224 11.4118 11.3146 11.5666 11.4178C11.9308 11.6606 12 11.8861 12 12C12 12.1139 11.9308 12.3394 11.5666 12.5822C11.4118 12.6854 11.2206 12.7776 11 12.849Z" fill="#111827"/>
							<path fill-rule="evenodd" clip-rule="evenodd" d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM11 5C11 4.44772 10.5523 4 10 4C9.44772 4 9 4.44772 9 5V5.09199C8.3784 5.20873 7.80348 5.43407 7.32398 5.75374C6.6023 6.23485 6 7.00933 6 8C6 8.99067 6.6023 9.76515 7.32398 10.2463C7.80348 10.5659 8.37841 10.7913 9.00001 10.908L9.00002 12.8492C8.60902 12.7223 8.31917 12.5319 8.15667 12.3446C7.79471 11.9275 7.16313 11.8827 6.74599 12.2447C6.32885 12.6067 6.28411 13.2382 6.64607 13.6554C7.20855 14.3036 8.05956 14.7308 9 14.9076L9 15C8.99999 15.5523 9.44769 16 9.99998 16C10.5523 16 11 15.5523 11 15L11 14.908C11.6216 14.7913 12.1965 14.5659 12.676 14.2463C13.3977 13.7651 14 12.9907 14 12C14 11.0093 13.3977 10.2348 12.676 9.75373C12.1965 9.43407 11.6216 9.20873 11 9.09199L11 7.15075C11.391 7.27771 11.6808 7.4681 11.8434 7.65538C12.2053 8.07252 12.8369 8.11726 13.254 7.7553C13.6712 7.39335 13.7159 6.76176 13.354 6.34462C12.7915 5.69637 11.9405 5.26915 11 5.09236V5Z" fill="#111827"/>
						</svg>`
				},

				activeTab: 1,
				promoCodeColumns,
				usersColumns,
				pagination: {
					search: '',
					loading: false,
                    perPage: 10,
                    currentPage: 1,
                    perPageOptions: [
						{label: "10", value: 10}, 
						{label: "20", value: 20}, 
						{label: "30", value: 30}, 
						{label: "50", value: 50}
					],
                    total: 10
                },
				userPagination: {
					search: '',
					loading: false,
                    perPage: 10,
                    currentPage: 1,
                    perPageOptions: [
						{label: "10", value: 10}, 
						{label: "20", value: 20}, 
						{label: "30", value: 30}, 
						{label: "50", value: 50}
					],
                    total: 10
                },
				promoCodes: [],
				promoCodeUsers: [],
				campaigns: [],
				deleteDataItem: {
					uuid: null,
					showModal: false,
				},
				isGenerateLoading: false,

				activeCode: "",
				miniWidth: 800,
				deviceWidth: window.innerWidth,

				newItem: {
					campaignId: null,
					loading: false,
					showModal: false,
				},

			}
		},

		created() {
			this.getPromoCodes();
			this.onResize();
			this.getPromoCodeUsers();
			this.getCampaigns();
			this.getAnalytics();
		},
		mounted() {
			this.$nextTick(() => {
				window.addEventListener('resize', this.onResize);
			})
		},

		beforeDestroy() { 
			window.removeEventListener('resize', this.onResize); 
		},

		methods: {
			
			copyCode(code) {
				navigator.clipboard.writeText(code);

				let message = "Promo-Code has been copied successfully"
				let type = "success"

				this.notify(type, message)
			},

			onResize() {
				this.deviceWidth = window.innerWidth;
			},
  
			getPromoCodes() {
                this.stat.loading = true;
				
                let url = `${this.$BACKEND_URL}/promo-codes/me`;

                this.$AXIOS.get(url).then(async(response) => {
                    if (response.status == 200) {
                        if(response.data.data != null) {
                            this.promoCodes = response.data.data;

							let activeCode = await response.data.data.filter(co => co.status == 1)

							if(activeCode.length > 0) {
								this.activeCode = activeCode[0].code
							}
                           
                        }else{
                            let message = response.data.message
							let type = "error"

                    		this.notify(type, message)
                        }
                    }
                    this.stat.loading = false;
                }).catch((error) => {
                    if(error.response != null && error.response.status == 401) {
                        this.$router.push(`/`);
                    }

                    let message = error.response != null &&  error.response.data != null ? error.response.data.message : "Something went wrong, please try again!";
                    let type = "error"

                    this.notify(type, message)

                    this.stat.loading = false;
                });
            },


			async getCampaigns() {

				let userDetails = await JSON.parse(localStorage.getItem("agent_details"));

				let url 

				if(userDetails != null && userDetails.type == 'admin') {
					url = `${this.$BACKEND_URL}/promo-codes/campaigns`;
				}else{
					url = `${this.$BACKEND_URL}/promo-codes/campaigns/public`;
				}

                this.$AXIOS.get(url).then(async(response) => {
                    if (response.status == 200) {
                        if(response.data.data != null) {
                            this.campaigns = response.data.data;
                           
                        }else{
                            let message = response.data.message
							let type = "error"

                    		this.notify(type, message)
                        }
                    }
					
                }).catch((error) => {
                    if(error.response != null && error.response.status == 401) {
                        this.$router.push(`/`);
                    }

                    let message = error.response != null &&  error.response.data != null ? error.response.data.message : "Something went wrong, please try again!";
                    let type = "error"

                    this.notify(type, message)

                });
            },


			getPromoCodeUsers() {
                this.pagination.loading = true;
				
                let url = `${this.$BACKEND_URL}/promo-codes/agent-users`;

                this.$AXIOS.get(url).then(async(response) => {
                    if (response.status == 200) {
                        if(response.data.data != null) {
                            this.promoCodeUsers = response.data.data;

							let message = response.data.message
							let type = "success"

                    		this.notify(type, message)

							// this.get()
                           
                        }else{
                            let message = response.data.message
							let type = "error"

                    		this.notify(type, message)
                        }
                    }
                    this.pagination.loading = false;
                }).catch((error) => {
                    if(error.response != null && error.response.status == 401) {
                        this.$router.push(`/`);
                    }

                    let message = error.response != null &&  error.response.data != null ? error.response.data.message : "Something went wrong, please try again!";
                    let type = "error"

                    this.notify(type, message)

                    this.pagination.loading = false;
                });
            },


			getAnalytics() {
                this.pagination.loading = true;
				
                let url = `${this.$BACKEND_URL}/promo-codes/analytics`;

                this.$AXIOS.get(url).then(async(response) => {
                    if (response.status == 200) {
                        if(response.data.data != null) {

							this.stat.bookingCount = response.data.data.bookingCount;
							this.stat.userCount = response.data.data.userCount;
							this.stat.totalEarnings = response.data.data.totalEarnings;

							let message = response.data.message
							let type = "success"

                    		this.notify(type, message)

							// this.get()
                           
                        }else{
                            let message = response.data.message
							let type = "error"

                    		this.notify(type, message)
                        }
                    }
                    this.pagination.loading = false;
                }).catch((error) => {
                    if(error.response != null && error.response.status == 401) {
                        this.$router.push(`/`);
                    }

                    let message = error.response != null &&  error.response.data != null ? error.response.data.message : "Something went wrong, please try again!";
                    let type = "error"

                    this.notify(type, message)

                    this.pagination.loading = false;
                });
            },


			onGeneratePromoCode() {
				this.newItem.showModal = true;
			},

			generatePromoCode() {
                this.isGenerateLoading = true;
				this.newItem.loading = true;

				if(this.newItem.campaignId != null && this.newItem.campaignId.length > 0) {

					let	url = `${this.$BACKEND_URL}/promo-codes/generate/${this.newItem.campaignId}`;
					
					this.$AXIOS.get(url).then((response) => {
						if (response.status >= 200 && response.status<= 210) {
							if(response.data.data != null && response.data.data.toString().length > 0) {
								// this.getPromoCodes()
								this.isGenerateLoading = false;
								this.newItem.showModal = false;

								let message = "Promo-code has been generated successfully: " + response.data.data
								let type = "success"

								this.notify(type, message)

								this.getPromoCodes();
							
							}else{
								let message = response.data.message
								let type = "error"

								this.notify(type, message)
							}

							this.isGenerateLoading = false;
							this.newItem.loading = false;
						}
						
						this.isGenerateLoading = false;
					}).catch((error) => {

						this.isGenerateLoading = false;
						this.newItem.loading = false;

						if(error.response != null && error.response.status == 401) {
							this.$router.push(`/`);
						}

						let message = error.response != null &&  error.response.data != null ? error.response.data.message : "Something went wrong, please try again!";
						let type = "error"

						this.notify(type, message)

						this.isGenerateLoading = false;
						this.newItem.loading = false;
					});
				}else{
					
					let type = "error"

					this.notify(type, "Please select campaign before you submit")
				}
            },


			showDeletionConfirmation(uuid) {
				this.deleteDataItem.uuid = uuid;
				this.deleteDataItem.showModal = true;
			},


			cancelItemDeletion() {
				this.deleteDataItem.uuid = null;
				this.deleteDataItem.showModal = false;
			},

			onDeleteItem() {

				this.deleteDataItem.uuid = null;
				this.deleteDataItem.showModal = false;

				this.notify("error", "It has not been implemented")

				// this.deleteDataItem.loading = true;

                // let url = `${this.$BACKEND_URL}/promo-codes/${this.deleteDataItem.uuid}`;

                // this.$AXIOS.delete(url).then((response) => {
                        
				// 	this.deleteDataItem.uuid = null;
				// 	this.deleteDataItem.showModal = false;
                
                //     this.deleteDataItem.loading = false;

				// 	let message = response.data.message;
                //     let type = "success"

                //     this.notify(type, message)

				// 	this.getPromoCodes();

                // }).catch((error) => {
                //     if(error.response != null && error.response.status == 401) {
                //         this.$router.push(`/`);
                //     }

                //     let message = error.response != null &&  error.response.data != null ? error.response.data.message : "Something went wrong, please try again!";
                //     let type = "error"

                //     this.notify(type, message)

                //     this.pagination.loading = false;
                // });
			},

			// notify(type, message) {
			// 	notification.open({
			// 		class: 'ant-notification-' + type,
			// 		message: message,
			// 		description

			// 		duration: 3.0,
			// 		style: {fontSize: "10px !important"}
			// 	});
			// },

			notify(type, message) {
                notification[type]({
                    message: message,
                    description: '',
                    placement: 'topRight',
                    duration: 5,
                });
                
            },
			
		}
	})

</script>

<style lang="scss">

	#generateBtn, #generateBtn:hover {
		background-color: #734EBF;
		color: white
	}

	#copyBtn, #copyBtn:hover {
		border: none;
		padding: 0px !important;
	}
	#copyTxt, copyTxt:hover {
		color: #734EBF;
	}
</style>